<template>
  <div class="card-carousel h-100">
    <div class="card-img" ref="theImage">
      <Image :imageName="currentImage" :width="theImageWidth" :height="theImageHeight" theClass="img-flud fill" />
      <!-- <img :src="url + '/static/uploads/images/' + currentImage" alt="" class="img-flud fill"> -->
      <div class="actions">
        <span @click="prevImage" class="prev">
          &#8249;
        </span>
        <span @click="nextImage" class="next">
          &#8250;
        </span>
      </div>
    </div>
    <div class="thumbnails">
      <div
        v-for="(image, key) in images"
        :key="image.id"
        :class="['thumbnail-image', (activeImage == key) ? 'active' : '']"
        @click="activateImage(key)"
      >
      <Image :imageName="image.imageUrl" height="50" />
        <!-- <img :src="url + '/static/uploads/images/' + image.imageUrl"> -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Carousel',
  components: {
    Image: defineAsyncComponent(() => import('./Image.vue'))
  },
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE,
      // Index of the active image
      activeImage: 0,
      // Hold the timeout, so we can clear it when it is needed
      autoSlideTimeout: null,
      // If the timer is stopped e.g. when hovering over the carousel
      stopSlider: false,
      // Hold the time left until changing to the next image
      timeLeft: 0,
      // Hold the interval so we can clear it when needed
      timerInterval: null,
      // Every 10ms decrease the timeLeft
      countdownInterval: 10,
      theImageWidth: 0,
      theImageHeight: 0
    }
  },
  async mounted () {
    await this.getDimensions()
  },
  computed: {
    // currentImage gets called whenever activeImage changes
    // and is the reason why we don't have to worry about the
    // big image getting updated
    currentImage () {
      // this.timeLeft = this.autoSlideInterval
      return this.images[this.activeImage].imageUrl
    },
    progressBar () {
      // Calculate the width of the progressbar
      return 100 - (this.timeLeft / this.autoSlideInterval) * 100
    }
  },
  methods: {
    getDimensions () {
      this.theImageWidth = this.$refs.theImage.clientWidth
      this.theImageHeight = this.$refs.theImage.clientHeight
    },
    // Go forward on the images array
    // or go at the first image if you can't go forward
    nextImage () {
      var active = this.activeImage + 1
      if (active >= this.images.length) {
        active = 0
      }
      this.activateImage(active)
    },
    // Go backwards on the images array
    // or go at the last image
    prevImage () {
      var active = this.activeImage - 1
      if (active < 0) {
        active = this.images.length - 1
      }
      this.activateImage(active)
    },
    activateImage (imageIndex) {
      this.activeImage = imageIndex
    }
  },
  created () {
    // Check if startingImage prop was given and if the index is inside the images array bounds
    if (this.startingImage &&
            this.startingImage >= 0 &&
            this.startingImage < this.images.length) {
      this.activeImage = this.startingImage
    }

    // Check if autoSlideInterval prop was given and if it is a positive number
    if (this.autoSlideInterval &&
            this.autoSlideInterval > this.countdownInterval) {
      // Start the timer to go to the next image
      this.startTimer(this.autoSlideInterval)
      this.timeLeft = this.autoSlideInterval
      // Start countdown to show the progressbar
      this.startCountdown()
    }
  },
  props: ['startingImage', 'images', 'autoSlideInterval', 'showProgressBar']
}
</script>

<style scoped>
.fill {
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  width: 100%;
}

.thumbnails {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
}

.thumbnail-image {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px;
  width: 20%;
  height: 50px;
}

.thumbnail-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 250ms;
}

.thumbnail-image:hover > img,
.thumbnail-image.active > img {
  opacity: 0.6;
  box-shadow: 2px 2px 6px 1px rgba(0,0,0, 0.5);
}

.card-img {
  position: relative;
  margin-bottom: 15px;
  height: calc(100% - 65px);
}

.card-img > img {
  display: block;
  margin: 0 auto;
}

.actions {
  font-size: 1.5em;
  height: 40px;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #585858;
}

.actions > span {
  cursor: pointer;
  transition: all 250ms;
  font-size: 45px;
}

.actions > span.prev {
  margin-left: 5px;
}

.actions > span.next {
  margin-right: 5px;
}

.actions > span:hover {
  color: #eee;
}
</style>
